import { useMutation, useQuery } from "@tanstack/react-query";

import {
  Button,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Select,
  Table,
  Typography,
} from "antd";

import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import useSearch from "../components/Search";
import UserUploadDataFirebase from "../components/userUploadDataFirebase";
import {
  addPhotos,
  deletePhoto,
  getCategories,
  getPhotos,
  updatePhoto,
} from "../utils/api";
import { useAuth } from "../utils/store";

const Photo = () => {
  const user = useAuth((state) => state.user);
  const [messageApi, contextHolder] = message.useMessage();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["photos"],
    queryFn: () => user?.getPhotos && getPhotos(),
  });

  const { mutate, isLoading: isLoading2 } = useMutation(
    async (values) => user?.deletePhotos && (await deletePhoto(values)),
    {
      onSuccess: () => {
        messageApi.success(`Deleted`);
        refetch();
      },
    }
  );
  const search = useSearch();
  const { data: categories, isLoading: isLoading3 } = useQuery({
    queryKey: ["categories"],
    queryFn: () => user?.getCategory && getCategories(),
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const columns = [
    {
      title: "S.no",
      dataIndex: "sno",

      render(value, record, index) {
        return (pagination.current - 1) * pagination.pageSize + index + 1;
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      render(value, record) {
        return (
          <div>
            {user?.updatePhotos && (
              <AddLinkData
                update={record}
                refech={refetch}
                categories={categories}
              />
            )}
            {user?.deletePhotos && (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => mutate(record._id)}
              >
                <Button danger style={{ marginTop: 10 }} loading={isLoading2}>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },

    {
      title: "Thumbnail",
      dataIndex: "low",

      render(value, record) {
        return <Image src={value} height={100} width={100} />;
      },
    },
    {
      title: "Image",
      dataIndex: "medium",

      render(value, record) {
        return <Image src={value} height={100} width={100} />;
      },
    },
    // {
    //   title: "High",
    //   dataIndex: "high",

    //   render(value, record) {
    //     return <Image src={value} height={100} width={100} />;
    //   },
    // },
    {
      title: "Title",
      dataIndex: "title",
      ...search("title"),
    },

    {
      title: "Price (in ₹)",
      dataIndex: "price",
    },
    {
      title: "Inflation (in %)",
      dataIndex: "inflation",
    },
    {
      title: "Total Purchase",
      dataIndex: "fixedInflation",
    },
    {
      title: "Deflation (in %)",
      dataIndex: "deflation",
    },
    {
      title: "Total Sales",
      dataIndex: "fixedDeflation",
    },

    {
      title: "Description",
      dataIndex: "about",
    },
    {
      title: "Category",
      dataIndex: ["category"],
      render(value, record) {
        return value?.map((item) => item.name).join(", ");
      },
    },
  ];
  return (
    <div
      style={{
        padding: 24,
      }}
    >
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
          Photos
        </Typography>
        {user?.addPhotos && (
          <div>
            <AddData refech={refetch} categories={categories} />
            <br />
            <AddLinkData refech={refetch} categories={categories} />
          </div>
        )}
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 1500 }}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          onChange: (page, pageSize) => {
            setPagination({ current: page, pageSize: pageSize });
          },
        }}
        footer={
          user?.getPhotos
            ? () => null
            : () => (
                <Typography.Text type="danger" strong>
                  You don't have permission to view this page
                </Typography.Text>
              )
        }
      />
    </div>
  );
};

export default Photo;

const AddData = ({
  refech,

  update,
  categories,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const type = Form.useWatch("low", form);
  console.log(type);

  const { mutate, isLoading } = useMutation(
    async (values) =>
      update ? await updatePhoto(update._id, values) : await addPhotos(values),
    {
      onSuccess: () => {
        messageApi.success(`Added`);
        //reset form
        !update && form.resetFields();
        refech();
        setVisible(false);
      },
      onError: (err) => {
        messageApi.error(err?.response?.data?.message);
      },
    }
  );

  return (
    <div>
      {contextHolder}
      <Modal
        title={update ? "Update Photo" : "Add Photo"}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 9 }}
          layout="horizontal"
          style={{
            maxWidth: 600,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          initialValues={
            update && {
              title: update.title,
              about: update.about,
              price: update.price,
              categoryId: update.category._id,
              inflation: update.inflation,
              deflation: update.deflation,
              fixedInflation: update.fixedInflation,
              fixedDeflation: update.fixedDeflation,
            }
          }
          onFinish={(values) => {
            const data = values;
            data.low = values.low?.file?.response?.url;
            data.medium = values.medium?.file?.response?.url;
            data.high = values.high?.file?.response?.url;
            console.log(data);

            mutate(data);
          }}
        >
          <Form.Item
            label="Low"
            name="low"
            required
            rules={[
              {
                required: true,
                message: "Please input your low image",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Medium"
            name="medium"
            required
            rules={[
              {
                required: true,
                message: "Please input your medium image",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="High"
            name="high"
            required
            rules={[
              {
                required: true,
                message: "Please input your high image",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Title"
            name="title"
            required
            rules={[
              {
                required: true,
                message: "Please input your title",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="About"
            name="about"
            required
            rules={[
              {
                required: true,
                message: "Please input your about",
              },
            ]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            label="Price (in ₹)"
            name="price"
            required
            rules={[
              {
                required: true,
                message: "Please input your price",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Inflation (in %)"
            name="inflation"
            required
            rules={[
              {
                required: true,
                message: "Please input your inflation",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Total Purchase"
            name="fixedInflation"
            required
            rules={[
              {
                required: true,
                message: "Please input Total Purchase",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Deflation (in %)"
            name="deflation"
            required
            rules={[
              {
                required: true,
                message: "Please input your deflation",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Total Sales"
            name="fixedDeflation"
            required
            rules={[
              {
                required: true,
                message: "Please input Total Sales",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Category"
            name="categoryId"
            required
            rules={[
              {
                required: true,
                message: "Please input your category",
              },
            ]}
          >
            <Select
              options={
                categories?.map((item) => {
                  return {
                    label: item.name,
                    value: item._id,
                  };
                }) || []
              }
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

const AddLinkData = ({ refech, update, categories }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const type = Form.useWatch("low", form);
  console.log(type);

  const { mutate, isLoading } = useMutation(
    async (values) =>
      update ? await updatePhoto(update._id, values) : await addPhotos(values),
    {
      onSuccess: () => {
        messageApi.success(`Added`);
        //reset form
        !update && form.resetFields();
        refech();
        setVisible(false);
      },
      onError: (err) => {
        messageApi.error(err?.response?.data?.message);
      },
    }
  );

  return (
    <div>
      {contextHolder}
      <Button type="primary" onClick={() => setVisible(true)}>
        {update ? "Update" : "Add"}
      </Button>
      <Modal
        title={update ? "Update Photo" : "Add Photo"}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 9 }}
          layout="horizontal"
          style={{
            maxWidth: 600,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          initialValues={
            update && {
              title: update.title,
              about: update.about,
              price: update.price,
              category: update?.category?.map((item) => item._id),
              inflation: update.inflation,
              deflation: update.deflation,
              fixedInflation: update.fixedInflation,
              fixedDeflation: update.fixedDeflation,
            }
          }
          onFinish={(values) => {
            mutate({
              ...values,
              low: values.low?.file?.response?.url,
              medium: values.medium?.file?.response?.url,
              high: values.medium?.file?.response?.url,
              fixedInflation: values?.fixedInflation || 0,
              fixedDeflation: values?.fixedDeflation || 0,
            });
          }}
        >
          <UserUploadDataFirebase
            type="photos"
            update={update}
            name="low"
            label="Thumbnail (.webp)"
          />
          <UserUploadDataFirebase
            type="photos"
            update={update}
            name="medium"
            label="Photo"
          />

          {/* <UserUploadDataFirebase
            type="photos"
            update={update}
            name="medium"
            label="Medium"
          />

          <UserUploadDataFirebase
            type="photos"
            update={update}
            name="high"
            label="High"
          /> */}

          <Form.Item
            label="Title"
            name="title"
            required
            rules={[
              {
                required: true,
                message: "Please input your title",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="About"
            name="about"
            required
            rules={[
              {
                required: true,
                message: "Please input your about",
              },
            ]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            label="Price (in ₹)"
            name="price"
            required
            rules={[
              {
                required: true,
                message: "Please input your price",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Inflation (in %)"
            name="inflation"
            required
            rules={[
              {
                required: true,
                message: "Please input your inflation",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item label="Total Purchase" name="fixedInflation">
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item label="Total Sales" name="fixedDeflation">
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Deflation (in %)"
            name="deflation"
            required
            rules={[
              {
                required: true,
                message: "Please input your deflation",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Category"
            name="category"
            required
            rules={[
              {
                required: true,
                message: "Please input your category",
              },
            ]}
          >
            <Select
              mode="multiple"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={
                categories?.map((item) => {
                  return {
                    label: item.name,
                    value: item._id,
                  };
                }) || []
              }
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
