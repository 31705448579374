import { useMutation, useQuery } from "@tanstack/react-query";

import {
  Button,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Select,
  Table,
  Typography,
} from "antd";

import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import useSearch from "../components/Search";
import MediaUploader from "../components/vemioUploader";
import {
  addVideo,
  deleteVideo,
  getCategories,
  getVideos,
  updateVideo,
} from "../utils/api";
import { useAuth } from "../utils/store";
function sortByRenditionAscending(videos) {
  return videos.sort((a, b) => {
    return parseInt(a.rendition) - parseInt(b.rendition);
  });
}
const Videos = () => {
  const user = useAuth((state) => state.user);
  const [messageApi, contextHolder] = message.useMessage();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["videos"],
    queryFn: () => user?.getVideos && getVideos(),
  });

  const { mutate, isLoading: isLoading2 } = useMutation(
    async (values) => user?.deleteVideos && (await deleteVideo(values)),
    {
      onSuccess: () => {
        messageApi.success(`Deleted`);
        refetch();
      },
    }
  );
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const search = useSearch();
  const { data: categories, isLoading: isLoading3 } = useQuery({
    queryKey: ["categories"],
    queryFn: () => user?.getCategory && getCategories(),
  });
  console.log(data);

  const columns = [
    {
      title: "S.no",
      dataIndex: "sno",

      render(value, record, index) {
        return (pagination.current - 1) * pagination.pageSize + index + 1;
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      render(value, record) {
        return (
          <div>
            {user?.updateVideos && (
              <AddData
                update={record}
                refech={refetch}
                categories={categories}
              />
            )}
            {user?.deleteVideos && (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => mutate(record._id)}
              >
                <Button danger style={{ marginTop: 10 }} loading={isLoading2}>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",

      render(value, record) {
        return <Image src={value} width={100} height={100} />;
      },
    },
    {
      title: "360p",
      dataIndex: "low",

      render(value, record) {
        return (
          <Link to={value} target="_blank">
            Link
          </Link>
        );
      },
    },
    {
      title: "720p",
      dataIndex: "medium",

      render(value, record) {
        return (
          <Link to={value} target="_blank">
            Link
          </Link>
        );
      },
    },
    {
      title: "1080p",
      dataIndex: "high",

      render(value, record) {
        return (
          <Link to={value} target="_blank">
            Link
          </Link>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      ...search("title"),
    },
    {
      title: "Price (in ₹)",
      dataIndex: "price",
    },
    {
      title: "Inflation (in %)",
      dataIndex: "inflation",
    },
    {
      title: "Total Purchase",
      dataIndex: "fixedInflation",
    },
    {
      title: "Deflation (in %)",
      dataIndex: "deflation",
    },
    {
      title: "Total Sales",
      dataIndex: "fixedDeflation",
    },
    {
      title: "about",
      dataIndex: "about",
    },
    {
      title: "Category",
      dataIndex: ["category"],
      render(value, record) {
        return value?.map((item) => item.name).join(", ");
      },
    },
  ];
  return (
    <div
      style={{
        padding: 24,
      }}
    >
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
          Videos
        </Typography>
        {user?.addVideos && (
          <AddData refech={refetch} categories={categories} />
        )}
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 2000 }}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          onChange: (page, pageSize) => {
            setPagination({ current: page, pageSize: pageSize });
          },
        }}
        footer={
          user?.getVideos
            ? () => null
            : () => (
                <Typography.Text type="danger" strong>
                  You don't have permission to view this page
                </Typography.Text>
              )
        }
      />
    </div>
  );
};

export default Videos;

const AddData = ({
  refech,

  update,
  categories,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [thumbnail, setThumbnail] = useState(null);

  const search = useSearch();
  const { mutate, isLoading } = useMutation(
    async (values) =>
      update ? await updateVideo(update._id, values) : await addVideo(values),
    {
      onSuccess: () => {
        messageApi.success(`Added`);
        //reset form
        !update && form.resetFields();
        refech();
        setVisible(false);
        setThumbnail(null);
      },
      onError: (err) => {
        messageApi.error(err?.response?.data?.message);
      },
    }
  );
  const videoid = Form.useWatch("thumbnail", form);
  const categoriesdata = Form.useWatch("category", form);
  console.log(categoriesdata);
  const { mutate: getThubnail, isLoading: isLoading4 } = useMutation({
    mutationFn: async (values) => {
      const response = await axios.get(
        `https://api.vimeo.com/videos/${videoid}/pictures?sizes=1920x1080`,
        {
          headers: {
            Authorization: `Bearer b1b42a75eaad2ee7988afd31b46e9739`,
          },
        }
      );
      console.log(response);
      return response;
    },
    onSuccess: (data) => {
      console.log(data?.data?.data?.[0]?.sizes);
      setThumbnail(data?.data?.data?.[0]?.sizes?.[0]?.link);
    },
  });

  console.log(thumbnail);

  return (
    <div>
      {contextHolder}
      <Button type="primary" onClick={() => setVisible(true)}>
        {update ? "Update" : "Add"}
      </Button>
      <Modal
        title={update ? "Update Video" : "Add Video"}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        {thumbnail && <Image src={thumbnail} width={100} height={100} />}
        <Form
          form={form}
          labelCol={{ span: 9 }}
          layout="horizontal"
          style={{
            maxWidth: 600,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          initialValues={
            update && {
              title: update.title,
              about: update.about,
              price: update.price,
              category: update?.category?.map((item) => item._id),
              inflation: update.inflation,
              deflation: update.deflation,
              fixedInflation: update?.fixedInflation,
              fixedDeflation: update?.fixedDeflation,
              high: update.high,
              medium: update.medium,
              low: update.low,
              sampleVideo: update.sampleVideo,
              thumbnail: update.thumbnail,
            }
          }
          onFinish={(values) => {
            mutate({
              ...values,

              fixedInflation: values?.fixedInflation || 0,
              fixedDeflation: values?.fixedDeflation || 0,
            });
          }}
        >
          {/* <Form.Item
            label="Thumbnail"
            name="thumbnail"
            required={update ? false : true}
            rules={[
              {
                required: !update,
                message: "Please input your Video",
              },
            ]}
          >
            <Space direction="horizontal" size="large">
              <Input />
              <Button
                icon={
                  thumbnail ? (
                    <CheckCircle size={14} color="green" />
                  ) : (
                    <UploadOutlined />
                  )
                }
                type={"dashed"}
                onClick={() => {
                  if (videoid) getThubnail(videoid);
                  else messageApi.error("Please enter the video id");
                }}
                disabled={isLoading4 || thumbnail}
                loading={isLoading4}
              >
                {thumbnail
                  ? "Completed"
                  : update
                  ? "Update Thumbnail"
                  : "Add Thumbnail"}
              </Button>
            </Space>
          </Form.Item> */}
          <MediaUploader
            type="videos"
            form={form}
            label={"Video"}
            name={"sampleVideo"}
            update={update}
            videoData={(data) => {
              const totalFiles = sortByRenditionAscending(
                data?.id?.data?.files
              )?.filter((item) => item.quality !== "hls");
              form.setFieldsValue({
                thumbnail:
                  data?.id?.data?.pictures?.sizes?.[
                    data?.id?.data?.pictures?.sizes?.length - 1
                  ]?.link,
                low:
                  totalFiles?.[totalFiles?.length - 3]?.link ??
                  totalFiles?.[0]?.link,
                medium:
                  totalFiles?.[totalFiles?.length - 2]?.link ??
                  totalFiles?.[0]?.link,
                high:
                  totalFiles?.[totalFiles?.length - 1]?.link ??
                  totalFiles?.[0]?.link,
                sampleVideo: data?.id?.data?.files?.find(
                  (item) => item.quality === "hls"
                )?.link,
              });
            }}
          />
          {/* <Form.Item
            label="Video Url"
            name="sampleVideo"
            required
            rules={[
              {
                required: true,
                message: "Please input your Video",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item> */}
          <Form.Item
            label="Thumbnail"
            name="thumbnail"
            required
            rules={[
              {
                required: true,
                message: "Please input your 360p",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="360p"
            name="low"
            required
            rules={[
              {
                required: true,
                message: "Please input your 360p",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="720p "
            name="medium"
            required
            rules={[
              {
                required: true,
                message: "Please input your 720p ",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="1080p "
            name="high"
            required
            rules={[
              {
                required: true,
                message: "Please input your 1080p ",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="Title"
            name="title"
            required
            rules={[
              {
                required: true,
                message: "Please input your title",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="About"
            name="about"
            required
            rules={[
              {
                required: true,
                message: "Please input your about",
              },
            ]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            label="Inflation (in %)"
            name="inflation"
            required
            rules={[
              {
                required: true,
                message: "Please input your inflation",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Deflation (in %)"
            name="deflation"
            required
            rules={[
              {
                required: true,
                message: "Please input your deflation",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item label="Total Purchase" name="fixedInflation">
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item label="Total Sales" name="fixedDeflation">
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Price (in ₹)"
            name="price"
            required
            rules={[
              {
                required: true,
                message: "Please input your price",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Categories"
            name="category"
            required
            rules={[
              {
                required: true,
                message: "Please input your category",
              },
            ]}
          >
            <Select
              mode="multiple"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={
                categories?.map((item) => {
                  return {
                    label: item.name,
                    value: item._id,
                  };
                }) || []
              }
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
